<template>
	<section class="hero is-dark is-fullheight">
		<div class="hero-body">
			<div class="container">
				<div class="columns is-centered">
					<div class="column is-5-tablet is-4-desktop is-3-widescreen">
          				<center><img alt="Vue logo" src="../assets/logo.png"></center>

						<form @submit.prevent="seConnecter" class="box">
							<div class="field">
								<label for="" class="label">Email</label>
								<div class="control">
									<input v-model="identifiant" type="email" placeholder="e.g. bobsmith@gmail.com" class="input" required>
								</div>
							</div>
							<div class="field">
								<label for="" class="label">Password</label>
								<div class="control">
									<input v-model="password" type="password" class="input" required>
								</div>
							</div>
							<div class="field">
								<button class="button is-success" :class="{'is-loading' : loading}">
									Se connecter
								</button>
							</div>
						</form>

						Mot de passe perdu ? <a href="https://boutique.canardpc.com/common/login">Utilisez la procédure de récupération du compte d'Aboshop ici</a>.

						<Message :message="message"></Message>
					</div>
				</div>
			</div>
		</div>
	</section>	
</template>
<script>
	export default {
		data() {
			return {
				loading:false,
				identifiant:'gilles@sopress.net',
				password:'motDePasse2021',
				message:''
			}
		},
		methods : {
			seConnecter() {
				this.loading=true;
				this.message='';
				let params = {
					identifiant:this.identifiant,
					pass:this.password
				};
				axios.post('login',params).then(response => {
					if(response.data.client) {
						this.$store.commit('setConnected',response.data.client);
					} 
					if(response.data.message) {
						this.message=response.data.message;
					}
				});

			}
		}
	}
</script>